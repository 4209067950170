<template>
  <div
      v-bind:style="{ width: imageWidth + 'px', height: imageHeight + 'px' }"
      @click="toggleZoom"
      class="zoom-image"
  >
    <Frame :pose="pose" class="frame" />
    <ZoomImage :pose="pose" :src="src" :class="{isZoomed: 'zoom-out'}" />
  </div>
</template>

<script>
import posed from "vue-pose";

const transition = {
  duration: 400,
  ease: [0.08, 0.69, 0.2, 0.99],
};

export default {
  props: ["imageWidth", "imageHeight", "src"],
  data: () => ({
    isZoomed: false,
  }),

  methods: {
    zoomIn() {
      window.addEventListener("scroll", this.zoomOut);
      this.isZoomed = true;
    },
    zoomOut() {
      window.removeEventListener("scroll", this.zoomOut);
      this.isZoomed = false;
    },
  },
  computed: {
    pose() {
      return this.isZoomed ? "zoomedIn" : "zoomedOut";
    },
    toggleZoom() {
      return this.isZoomed ? this.zoomOut : this.zoomIn;
    },
  },
  components: {
    Frame: posed.div({
      zoomedOut: {
        applyAtEnd: { display: "none" },
        opacity: 0,
      },
      zoomedIn: {
        applyAtStart: { display: "block" },
        opacity: 1,
      },
    }),
    ZoomImage: posed.img({
      zoomedOut: {
        position: "static",
        width: "auto",
        height: "auto",
        flip: true,
        transition,
        maxWidth: '100%',
      },
      zoomedIn: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        flip: true,
        transition,
        maxWidth: '80%',
      },
    }),
  },
};
</script>

<style>
.zoom-image img {
  cursor: zoom-in;
  display: block;
  margin: auto;
  z-index: 999;
}

.zoom-image .frame {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background: rgba(31, 45, 61, 0.78);
  transform: translateZ(0);
  height: 100%;
  z-index: 998;
}
</style>
