<script>
import {
	ArrowUpIcon,
	MailIcon,
	UserIcon,
	KeyIcon,
} from "vue-feather-icons";
import Footer from "@/components/footer";
import Pricing from "@/components/pricing";
import Navbar from "@/components/navbar";
import ZoomImage from '@/components/ZoomImage';

export default {
	data() {
		return {
			plans: [],
			email: '',
		};
	},
	components: {
		Navbar,
		Footer,
		Pricing,
		ArrowUpIcon,
		MailIcon,
		UserIcon,
		KeyIcon,
		ZoomImage,
	},
	mounted() {
		this.getPrices();

		window.onscroll = function () {
			onwindowScroll();
		};

		function onwindowScroll() {
			if (
				document.body.scrollTop > 50 ||
				document.documentElement.scrollTop > 50
			) {
				document.getElementById("topnav").classList.add("nav-sticky");
			} else {
				document.getElementById("topnav").classList.remove("nav-sticky");
			}

			if (
				document.body.scrollTop > 100 ||
				document.documentElement.scrollTop > 100
			) {
				document.getElementById("back-to-top").style.display = "inline";
			} else {
				document.getElementById("back-to-top").style.display = "none";
			}
		}
	},
	methods: {
		getPrices() {
			this.$http.get('/api/subscriptions').then(response => {
				this.plans = response.data.plans
			}).catch(error => {
				console.log('error', error)
			})
		},
		submitEmail() {
			this.$store.commit('setUserEmail', this.email);
			this.$router.push('buy');
		},
	},
};
</script>

<template>
	<div>
		<Navbar/>

		<div v-scroll-spy>
			<!-- Hero Start -->
			<section
				class="bg-home bg-light d-flex align-items-center"
				style="
          background: url('images/onepage/bg.png') center center;
          height: auto;
        "
				id="home"
			>
				<div class="container">
					<div class="row justify-content-center mb-5">
						<div class="col-md-9 text-center mt-0 mt-md-5 pt-0 pt-md-5">
							<div class="title-heading margin-top-100">
								<h1
									class="heading mb-3"
									data-aos="fade-up"
									data-aos-duration="1000"
								>
									Innovative tools for professional ticket resellers
								</h1>
								<p
									class="para-desc mx-auto text-muted fs-1 text-big text-primary"
									data-aos="fade-up"
									data-aos-duration="1400"
								>
									Start your 10-day free trial
								</p>

								<div
									class="text-center subcribe-form mt-4 pt-2"
									data-aos="fade-up"
									data-aos-duration="1800"
								>
									<form @submit.prevent="submitEmail">
										<div class="form-group mb-0">
											<input
												type="email"
												id="email"
												name="email"
												class="shadow bg-white rounded-pill"
												required=""
												placeholder="Email Address"
												v-model="email"
											/>
											<button type="submit" class="btn btn-pills btn-primary">
												Get Started
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>

						<!--end col-->
					</div>
					<!--end row-->
				</div>
				<!--end container-->

			</section>
			<!--end section-->
			<!-- Hero End -->

			<!-- Features Start -->
			<section class="section pb-0" id="service">

				<div class="container">
					<div class="row">

						<div class="col-md-3 col-12">
							<div class="features mt-5">
								<div class="image position-relative d-inline-block">
									<img src="images/homepage_images/icons/wr.png" alt="" class="icon rounded">
								</div>

								<div class="content mt-4">
									<h5>Waiting Room&nbsp;Q</h5>
									<p class="text-muted mb-0">
										2000+?<br/>
										Track your waiting room queue position across all your purchasing accounts.
									</p>
								</div>
							</div>
						</div>
						<!--end col-->

						<div class="col-md-3 col-12 mt-5">
							<div class="features">
								<div class="image position-relative d-inline-block">
									<img src="images/homepage_images/icons/shaders.png" alt="" class="icon rounded">
								</div>

								<div class="content mt-4">
									<h5>Price Map Shader</h5>
									<p class="text-muted mb-0">
										The Map Shader extension accelerates the discovery of different price points
										with the use of color coding.
									</p>
								</div>
							</div>
						</div>
						<!--end col-->

						<div class="col-md-3 col-12">
							<div class="features mt-5">
								<div class="image position-relative d-inline-block">
									<img src="images/homepage_images/icons/2fa.png" alt="" class="icon rounded">
								</div>

								<div class="content mt-4">
									<h5>Email 2FA Code Extension</h5>
									<p class="text-muted mb-0">
										Eliminate the hassle of combing though emails for authentication codes.
									</p>
								</div>
							</div>
						</div>
						<!--end col-->

						<div class="col-md-3 col-12 mt-5">
							<div class="features">
								<div class="image position-relative d-inline-block">
									<img src="images/homepage_images/icons/distribute.png" alt="" class="icon rounded">
								</div>

								<div class="content mt-4">
									<h5>Distribte</h5>
									<p class="text-muted mb-0">
										Distribte gives users the ability to monitor active employee shopping carts
										and approve or deny purchase requests with a click of a button.
									</p>
								</div>
							</div>
						</div>
						<!--end col-->
					</div>
					<div id="Shader"></div>
					<br>
					<div class="container mt-100 mt-60">
						<div class="row align-items-center">
							<div class="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 text-center">
								<div class="section-title ml-lg-5">
									<h4
										class="title mb-4"
										data-aos="fade-up"
										data-aos-duration="1000"
									>
										Identifying price points has never been easier
									</h4>
									<p
										class="text-muted"
										data-aos="fade-up"
										data-aos-duration="1400"
									>
										Discovering the best prices for Ticketmaster and LiveNation events has never been easier thanks to our innovative Stubtools Map Shader extension.
									</p>
								</div>
							</div>
							<!--end col-->
						</div>
						<!--end row-->
						<!-- start row -->
						<div class="row">
							<div class="col-lg-5 col-md-6 d-flex justify-content-center align-items-center">
								<ZoomImage
									src="images/homepage_images/shader.png"
									data-aos="fade-up"
									data-aos-duration="1800"
									class="img-fluid"
									alt=""
									style="max-width: 90%; margin: auto"
								/>
							</div>
							<div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
								<div class="section-title">
									<h6 class="title mb-4"
									    data-aos="fade-up"
									    data-aos-duration="1000">
										Features Include
									</h6>
									<div class="d-flex justify-content-center align-items-center text-muted"
									     data-aos="fade-up"
									     data-aos-duration="1400">
										<ul role="list">
											<li>Automatically matches seat locations to their corresponding color codes, allowing you to quickly and easily identify the best deals.</li>
											<li>Generates a custom color-coded map that clearly displays available price points, making it easier than ever to find the perfect seats at the right price.</li>
											<li>Aggregates all the unpurchased seats and displays them in an easy-to-read legend on the side of the seating map, making it easy to quickly see which seat prices are still available.</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<!-- end row -->
					</div>
					<!--end container-->

					<div id="WR"></div>
					<br>
					<div class="row align-items-center  mt-100 mt-60">
						<div class="col-lg-6 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
							<div class="section-title">
								<h4
									class="title mb-4"
									data-aos="fade-up"
									data-aos-duration="1000"
								>
									Are you 2000+? Which of your accounts have priority?
								</h4>
								<p
									class="text-muted text-justify"
									data-aos="fade-up"
									data-aos-duration="1400"
								>Track & store your waiting room queue positions across all purchasing accounts.</p>
							</div>
						</div>
						<!--end col-->

						<div class="col-md-6">
							<ZoomImage
								data-aos="fade-up"
								data-aos-duration="1800"
								class="img-fluid"
								alt=""
								style="border-radius: 3px"
								src="images/homepage_images/waiting.png"
							/>
						</div>
						<!--end col-->
					</div>
					<!--end row-->

					<!--end row-->
					<div id="2FA"></div>
					<br>
					<div class="row align-items-center  mt-100 mt-60">
						<div class="col-lg-5 col-md-6">
							<img
								src="images/homepage_images/gmail2fa.png"
								data-aos="fade-up"
								data-aos-duration="1800"
								class="img-fluid rounded"
								alt=""
								style="max-width: 350px; box-shadow: 3px 3px 15px #11001B"
							/>
						</div>
						<!--end col-->

						<div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
							<div class="section-title ml-lg-5">
								<h4
									class="title mb-4"
									data-aos="fade-up"
									data-aos-duration="1000"
								>
									Deliver your 2FA (Two Factor) codes directly in browser tabs
								</h4>
								<p
									class="text-muted text-justify"
									data-aos="fade-up"
									data-aos-duration="1400"
								>
									Experience hassle-free login with our new Email 2FA feature. Say goodbye to the tedious task of
									combing through emails for authentication codes. Our powerful tool locates 2FA tokens in your email
									and seamlessly displays them on the associated tab where the request took place, ensuring a faster
									login process. Implementation is a breeze and you can enjoy the benefits of quicker login times in no
									time.
								</p>
							</div>
						</div>
						<!--end col-->
					</div>
					<!--end row-->
				</div>
				<!--end container-->

				<!-- Modal Content Start -->
				<b-modal
					id="trialform"
					content-class="bg-white"
					hide-footer
					title="14 Days Free Trial"
				>
					<div class="modal-body">
						<div class="feature-form">
							<img
								src="images/illustrator/Mobile_notification_SVG.svg"
								alt=""
							/>

							<div class="content mt-4 pt-2">
								<form>
									<div class="row">
										<div class="col-lg-12">
											<div class="form-group ">
												<label>Name : <span class="text-danger">*</span></label>
												<div class="position-relative">
													<user-icon class="fea icon-sm icons"></user-icon>
													<input
														type="text"
														class="form-control pl-5"
														placeholder="Name"
														name="name"
														required=""
													/>
												</div>
											</div>
										</div>
										<!--end col-->

										<div class="col-lg-12">
											<div class="form-group">
												<label
												>Email : <span class="text-danger">*</span></label
												>
												<div class="position-relative">
													<mail-icon class="fea icon-sm icons"></mail-icon>
													<input
														type="email"
														class="form-control pl-5"
														placeholder="Email"
														name="email"
														required=""
													/>
												</div>
											</div>
										</div>
										<!--end col-->

										<div class="col-lg-12">
											<div class="form-group ">
												<label
												>Password : <span class="text-danger">*</span></label
												>
												<div class="position-relative">
													<key-icon class="fea icon-sm icons"></key-icon>
													<input
														type="password"
														class="form-control pl-5"
														placeholder="Password"
														required=""
													/>
												</div>
											</div>
										</div>
										<!--end col-->

										<div class="col-lg-12 mt-2 mb-0">
											<button class="btn btn-primary btn-block">Login</button>
										</div>
										<!--end col-->
									</div>
									<!--end row-->
								</form>
							</div>
						</div>
					</div>
				</b-modal>
				<!-- Modal Content End -->

			</section>
			<!--end section-->

			<!-- Pricing Start -->
			<div id="Pricing"></div>
			<br>
			<section class="section">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-12 text-center">
							<div class="section-title mb-4 pb-2">
								<h4
									class="title mb-4"
									data-aos="fade-up"
									data-aos-duration="1000"
								>
									Our Pricing
								</h4>
								<p
									class="text-muted para-desc mb-0 mx-auto"
									data-aos="fade-up"
									data-aos-duration="1400"
								>
									Start working with
									<span class="text-primary font-weight-bold">Stubtools</span>
									to provide everything you need.
								</p>
							</div>
						</div>
						<!--end col-->
					</div>
					<!--end row-->

					<Pricing :plans="plans"/>
				</div>
				<!--end container-->
			</section>
			<!--end section-->
			<!-- Pricing End -->

			<!-- Pricing Start -->
			<section class="section" id="contact">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-12 text-center">
							<div class="section-title mb-4 pb-2">
								<h4
									class="title mb-4"
									data-aos="fade-up"
									data-aos-duration="1000"
								>
									Contact us
								</h4>
								<p>
									<router-link to="/support" class="btn btn-outline-primary" target="_blank">
										Contact support
									</router-link>
								</p>
								<p>Or</p>
								<p
									class="text-muted para-desc mb-0 mx-auto"
									data-aos="fade-up"
									data-aos-duration="1400"
								>
									Email us at
									<a href="mailto:hello@stubtools.com"><span
										class="text-primary font-weight-bold">hello@stubtools.com</span></a>
								</p>
							</div>
						</div>
						<!--end col-->
					</div>
					<!--end row-->
				</div>
				<!--end container-->
			</section>
			<!--end section-->
			<!-- Pricing End -->
		</div>
		<!--end section-->
		<Footer/>
		<!-- Back to top -->
		<a
			href="javascript: void(0);"
			class="btn btn-icon btn-primary back-to-top"
			id="back-to-top"
			v-scroll-to="'#topnav'"
		>
			<arrow-up-icon class="icons"></arrow-up-icon>
		</a>
		<!-- Back to top -->
	</div>
</template>

<style>
.modal-content {
	background-color: transparent;
}

.subcribe-form input {
	color: #f7961c !important;
}

ul {
}
ul > li {
	text-align: start;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
}

.text-big {
	font-size: 1.5em !important;
	font-weight: bold;
}
</style>
